import React, { useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { EdgeTagFilter } from "../components/common/UrlFormater";
import ContentContext from "../components/common/ContentContext";
import { Layout, PostCard } from "../components/common";
import { MetaData } from "../components/common/meta";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost;
    const [state, setState] = useContext(ContentContext);
    const posts = EdgeTagFilter(data.allGhostPost.edges, [
        state.lng,
        state.mkt,
        state.sct,
        `#ren@posts`,
    ]).slice(0, 3);
    //console.log(posts)
    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        <div className="post-full-content">
                            <h1 className="content-title">{post.title}</h1>
                        </div>
                        {post.feature_image1 ? (
                            <figure className="post-feature-image">
                                <GatsbyImage
                                    image={
                                        post.feature_image1.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt={post.title}
                                />
                            </figure>
                        ) : null}
                        <section className="post-full-content">
                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                    <div className="article-suggestions">
                        <h2>Περισσότερες προτάσεις</h2>
                        <section className="post-feed">
                            {posts.map(({ node }) => (
                                // The tag below includes the markup for each post - components/common/PostCard.js
                                <PostCard key={node.id} post={node} />
                            ))}
                        </section>
                    </div>
                </div>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image1: PropTypes.object,
        }).isRequired,
        allGhostPost: PropTypes.object,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

// export const postQuery = graphql`
//     query($slug: String!) {
//         ghostPost(slug: { eq: $slug }) {
//             ...GhostPostFields
//         }
//     }
// `

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
        allGhostPost(sort: { order: DESC, fields: published_at }) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
